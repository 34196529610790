/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* Mukta Mahee */
@font-face {
  font-family: Mukta Mahee;
  src: url("./assets/fonts/mukta-mahee/mukta-mahee-extra-light.ttf") format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: Mukta Mahee;
  src: url('./assets/fonts/mukta-mahee/mukta-mahee-light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: Mukta Mahee;
  src: url('./assets/fonts/mukta-mahee/mukta-mahee-regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Mukta Mahee;
  src: url('./assets/fonts/mukta-mahee/mukta-mahee-medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: Mukta Mahee;
  src: url('./assets/fonts/mukta-mahee/mukta-mahee-semi-bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: Mukta Mahee;
  src: url('./assets/fonts/mukta-mahee/mukta-mahee-bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Mukta Mahee;
  src: url('./assets/fonts/mukta-mahee/mukta-mahee-extra-bold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
/* @font-face {
  font-family: Mukta Mahee;
  src: url('./assets/fonts/Roboto_Slob/RobotoSlab-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: Mukta Mahee;
  src: url('./assets/fonts/Roboto_Slob/RobotoSlab-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
} */

/* Roboto slab */
@font-face {
  font-family: Roboto Slab;
  src: url("./assets/fonts/roboto-slab/roboto-slab-bold.ttf") format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Roboto Slab;
  src: url("./assets/fonts/roboto-slab/roboto-slab-black.ttf") format('truetype');
  font-weight: 400;
  font-style: normal;
}
/* @font-face {
  font-family: Roboto Slab;
  src: url("./assets/fonts/roboto-slab/RobotoSlab-ExtraBold.ttf") format('truetype');
  font-weight: 700;
  font-style: normal;
} */
/* scroll bar  */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 11px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #EF4823;
  border-radius: 11px;
}