.mentions__user{
font-size: 16px;
 color: #EF4823;
 position: relative;
 z-index: 100;
}

/* .mentions__ingredients{
font-size: 16px;
border: 1px solid red;
position: relative;
 z-index: 100;
 background-color: blue;
} */